import React, { useState } from 'react';
import './index.css';  // Global styles
import './App.css'; // Ensure you import your CSS file here
import { useDropzone } from 'react-dropzone';

function App(props) {
const [isDragOver, setIsDragOver] = useState(false);


  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'text/csv': []
    },
    onDragOver: () => setIsDragOver(true),
    onDragLeave: () => setIsDragOver(false),
    onDrop: () => setIsDragOver(false)
  });

  const acceptedFileItems = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map(e => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  return (
    <section className="container">
      <div {...getRootProps({ className: `dropzone ${isDragOver ? 'dragover' : ''}` })}>
        <input {...getInputProps()} />
        <div>
        <p>Drag 'n' drop some files here, or click to select files</p>
        <em>(Only *.xlsx, *.xlsm, and *.csv files will be accepted)</em>
        </div>
      </div>
      <aside>
        <h4>Accepted files</h4>
        <ul>{acceptedFileItems}</ul>
        <h4>Rejected files</h4>
        <ul>{fileRejectionItems}</ul>
      </aside>
    </section>
    
  );
}

export default App;